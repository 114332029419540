import { render, staticRenderFns } from "./SchoolEvent.vue?vue&type=template&id=428a3286"
import script from "./SchoolEvent.vue?vue&type=script&lang=js"
export * from "./SchoolEvent.vue?vue&type=script&lang=js"
import style0 from "./SchoolEvent.vue?vue&type=style&index=0&id=428a3286&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports